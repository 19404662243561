import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ChangeEvent, ComponentProps, HTMLInputTypeAttribute, KeyboardEvent } from "react";
import { useFormContext as useReactHookFormContext } from "react-hook-form";

import cn from "../../../util/cn.js";
import { useFieldIssue } from "../../hooks/useFieldIssue.js";
import type CommonFieldOptions from "../../models/CommonFieldOptions.js";
import FormFieldIdentitifer from "../core/FormFieldIdentitifer.js";
import type { InputWrapperProps } from "./InputWrapper.js";
import InputWrapper from "./InputWrapper.js";

export const TextInputVariants = cva("block w-full rounded-md placeholder:text-secondary/50", {
  variants: {
    intent: {
      normal:
        "border-base sm:text-sm focus:outline-none focus:ring-accent focus:border-accent bg-surface focus:bg-surface placeholder-secondary",
      chromeless: "border-none p-0 focus:outline-none focus:border-0 focus:ring-0 bg-transparent",
      deemphasized: "border text-sm focus:ring-accent focus:border-accent",
    },
    hidden: {
      true: "hidden",
    },
    disabled: {
      true: "bg-surface-muted cursor-not-allowed text-secondary",
    },
    readOnly: {
      true: "bg-surface-muted cursor-not-allowed text-secondary",
    },
    error: {
      true: "border-red-500",
    },
  },
});

interface Props extends CommonFieldOptions {
  className?: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  inputClassName?: string;
  defaultValue?: any;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  inputStyle?: "NORMAL" | "CHROMELESS" | "DEEMPHASIZED";
  onChange?: (text: string) => void;
  value?: string;

  /**
   * Props to pass to the input element. Simple passthrough
   */
  inputProps?: ComponentProps<"input">;
} // And prop types of HTML Input Elements

const TextInput = ({
  className,
  inputClassName,
  name,
  type = "text",
  placeholder,
  required,
  disabled,
  hidden,
  autoFocus,
  readOnly,
  onKeyDown,
  defaultValue,
  onChange: _onChange,
  inputProps,
  intent = "normal",
  value,
  ...otherProps
}: Props & VariantProps<typeof TextInputVariants> & InputWrapperProps) => {
  const methods = useReactHookFormContext();

  const issue = useFieldIssue(name);

  const registration = methods?.register(name);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    void registration?.onChange(e);
    _onChange?.(e.target.value);
  };

  const inner = (
    <>
      <FormFieldIdentitifer name={name} type={type} />
      <input
        autoFocus={autoFocus}
        hidden={hidden}
        type={type}
        required={required}
        value={value}
        disabled={disabled ?? readOnly}
        readOnly={readOnly}
        id={name}
        onKeyDown={onKeyDown}
        autoComplete="off"
        {...(registration || {})}
        {...inputProps}
        onChange={onChange}
        className={cn(
          TextInputVariants({
            intent,
            disabled,
            readOnly,
            hidden,
            error: issue !== undefined,
          }),
          "focus:ring-0",
          inputClassName
        )}
        placeholder={placeholder}
        //   aria-describedby="email-description"
      />
    </>
  );

  if (intent === "chromeless") {
    return (
      <div className={cn("w-full", className)} hidden={hidden}>
        {inner}
      </div>
    );
  } else {
    return (
      <div className={cn("w-full", className)} hidden={hidden}>
        <InputWrapper {...otherProps} htmlFor={name}>
          {inner}
        </InputWrapper>
      </div>
    );
  }
};

export default TextInput;
